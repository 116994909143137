import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, computed, DestroyRef, effect, HostBinding, inject, input, OnInit, viewChild, viewChildren } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { EventType, Router } from '@angular/router';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';

import { debounceTime, filter, map } from 'rxjs';

import { LangDefinition, TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { Store } from '@ngrx/store';

import { ChrComponentsModule, ChrNavGroupDirective, ChrSideNavDirective } from '@ciphr-design-system/angular';

import { AppBrandingService, navStyleMap } from '@ciphr/core/app-branding';
import {
  appNavigationFeatureActions,
  selectNavigationHidden,
  selectNavigationMenu,
  selectNavigationOpened,
  selectNavigationSearch,
} from '@ciphr/core/app-navigation/state';
import { AvatarDirective } from '@ciphr/shared/avatar';
import { NoResultComponent } from '@ciphr/ui';

import { AppNavigationLinkDirective } from './app-navigation-link.directive';
import { AUTH_PANEL_SERVICE } from '../auth-panel-service.token';

type HrefTarget = '_self' | '_blank' | '_top';

@Component({
  selector: 'ciphr-app-navigation',
  standalone: true,
  imports: [
    NgTemplateOutlet,
    ReactiveFormsModule,
    TranslocoDirective,
    ChrComponentsModule,
    AppNavigationLinkDirective,
    AvatarDirective,
    NoResultComponent,
  ],
  templateUrl: './app-navigation.component.html',
  styleUrls: ['./app-navigation.component.scss'],
})
export class AppNavigationComponent implements OnInit {
  private readonly appBrandingService = inject(AppBrandingService);
  private readonly authPanelService = inject(AUTH_PANEL_SERVICE);
  private readonly breakpointObserver = inject(BreakpointObserver);
  private readonly destroyRef = inject(DestroyRef);
  private readonly router = inject(Router);
  private readonly store = inject(Store);
  private readonly translocoService = inject(TranslocoService);

  desktopVersionUrl = input<string | null>(null);
  searchDisabled = input(false, { transform: coerceBooleanProperty });

  private readonly navGroups = viewChildren(ChrNavGroupDirective);
  private readonly sideNav = viewChild(ChrSideNavDirective);

  readonly languages = this.translocoService.getAvailableLangs() as LangDefinition[];

  readonly activeLanguageId = toSignal(this.translocoService.langChanges$);
  readonly activeLanguageLabel = computed(() => this.languages.find((language) => language.id === this.activeLanguageId())?.label);
  readonly authIssuer = this.authPanelService.issuer;
  readonly isSsoAdmin = this.authPanelService.isSsoAdmin;
  readonly handset = toSignal(this.breakpointObserver.observe(Breakpoints.Handset).pipe(map((state) => state.matches)), {
    initialValue: false,
  });
  readonly hidden = computed(() => !!(this.navigationHidden() && this.handset()));
  readonly logo = this.appBrandingService.brandingLogo;
  readonly navigationBrandingLevel = computed(() => {
    const style = this.appBrandingService.navigationBrandingLevel();

    return navStyleMap.get(style) || 'neutral';
  });
  readonly navigationHidden = this.store.selectSignal(selectNavigationHidden);
  readonly navigationMenu = this.store.selectSignal(selectNavigationMenu);
  readonly search = this.store.selectSignal(selectNavigationSearch);
  readonly searchControl = new FormControl('');
  readonly username = this.authPanelService.username;
  readonly navigationOpened = this.store.selectSignal(selectNavigationOpened);
  readonly blankTargets = ['academy'];

  readonly languagesIconsMap = new Map([
    ['en-GB', 'flag-uk'],
    ['de', 'flag-de'],
  ]);
  readonly productsIconsMap = new Map([
    ['academy', 'old-building'],
    ['external', 'puzzle'],
    ['home', 'home'],
    ['hr', 'users'],
    ['lms', 'student-hat'],
    ['mypay', 'money'],
    ['recruit', 'user-magnifier'],
    ['settings', 'cog'],
  ]);

  @HostBinding('class.hidden') get hiddenClass(): boolean {
    return this.hidden();
  }

  constructor() {
    effect(() => {
      const someActiveNavGroup = this.navGroups().some((group) => group.active);

      if (this.handset()) {
        !this.hidden() && !someActiveNavGroup && (this.navGroups()[0].active = true);
      }
    });
  }

  ngOnInit(): void {
    this.store.dispatch(appNavigationFeatureActions.loadNavigationMenu());

    this.store
      .select(selectNavigationOpened)
      .pipe(filter((opened) => !opened))
      .subscribe(() => {
        const activeNavGroup = this.navGroups()?.find((group) => group.active);
        activeNavGroup && (activeNavGroup.active = false);
      });

    this.searchControl.valueChanges.pipe(debounceTime(400), takeUntilDestroyed(this.destroyRef)).subscribe((searchValue) => {
      const action = searchValue
        ? appNavigationFeatureActions.searchNavigationLinks({ searchValue })
        : appNavigationFeatureActions.resetNavigationSearch();

      this.store.dispatch(action);
    });

    this.router.events
      .pipe(filter((event) => event.type === EventType.NavigationEnd || event.type === EventType.NavigationSkipped))
      .subscribe(() => this.handset() && !this.hidden() && this.toggleNavigationVisibility());
  }

  getLanguageIcon(languageId: string): string {
    return this.languagesIconsMap.get(languageId) || '';
  }

  getProductIcon(application: string): string {
    return this.productsIconsMap.get(application.toLowerCase()) ?? 'question';
  }

  logOut(): void {
    this.authPanelService.logOut();
  }

  selectActiveLanguage(languageId: string): void {
    this.translocoService.setActiveLang(languageId);
  }

  toggleNavigation(): void {
    this.store.dispatch(appNavigationFeatureActions.setNavigationOpened({ navigationOpened: !!this.sideNav()?.opened }));
  }

  toggleNavigationVisibility(): void {
    this.store.dispatch(appNavigationFeatureActions.toggleNavigationVisibility());
  }

  getTargetType(application: string): HrefTarget {
    return this.blankTargets.includes(application.toLowerCase()) ? '_blank' : '_self';
  }
}
