import { AfterContentInit, Component, computed, contentChild, contentChildren, effect, EventEmitter, input, Output } from '@angular/core';

import { TranslocoDirective } from '@jsverse/transloco';

import { ChrButtonDirective, ChrButtonIconDirective, ChrComponentsModule } from '@ciphr-design-system/angular';

import { CollectionState } from '@ciphr/utils/collections';

@Component({
  selector: 'ciphr-bulk-actions-header',
  standalone: true,
  imports: [TranslocoDirective, ChrComponentsModule],
  templateUrl: './bulk-actions-header.component.html',
  styleUrls: ['./bulk-actions-header.component.scss'],
})
export class BulkActionsHeaderComponent<ListItem extends { id: number | string }> implements AfterContentInit {
  listItems = input.required<ListItem[]>();
  selection = input.required<CollectionState['selection'] | null>();

  @Output() allOnPageSelectionToggle = new EventEmitter<boolean>();

  actionButtons = contentChildren(ChrButtonDirective);
  mobileActionsButton = contentChild(ChrButtonIconDirective);

  private selectionOnCurrentPage = computed(() => {
    return this.listItems().filter((item) => this.selection()?.has(item.id));
  });

  allOnPageSelected = computed(() => {
    return !!this.listItems().length && this.listItems().length === this.selectionOnCurrentPage()?.length;
  });
  anyOnPageSelected = computed(() => !!this.selectionOnCurrentPage()?.length && !this.allOnPageSelected());
  selectionLength = computed(() => this.selection()?.size);

  constructor() {
    effect(() => {
      this.setActionButtonsDisabledState(!this.selection()?.size);
    });
  }

  ngAfterContentInit(): void {
    this.setActionButtonsDisabledState(true);
  }

  emitAllOnPageSelectionToggle({ target }: Event): void {
    this.allOnPageSelectionToggle.emit(!!(target as HTMLInputElement).value);
  }

  private setActionButtonsDisabledState(disabled: boolean): void {
    const mobileActionsButton = this.mobileActionsButton();

    mobileActionsButton && (mobileActionsButton.disabled = disabled);

    this.actionButtons()?.forEach((button) => {
      button.disabled = disabled;
    });
  }
}
