<div *transloco="let t; read: 'COMMON.COLLECTIONS.MULTI_SELECTION'" class="selection">
  <chr-checkbox
    [checked]="allOnPageSelected()"
    [indeterminate]="anyOnPageSelected()"
    size="s"
    data-test-id="selectAllCheckbox"
    (change)="emitAllOnPageSelectionToggle($event)"
  >
    {{ t("SELECT_ALL_ON_PAGE") }}
  </chr-checkbox>

  <span class="separator">•</span>

  <span data-test-id="selectionCounter" aria-live="polite">{{ t("SELECTION_SIZE", { itemsNumber: selectionLength() }) }}</span>
</div>

<div class="actions">
  <ng-content select="chr-button" />
</div>

<div class="mobile-actions">
  <ng-content select="chr-button-icon" />
</div>

<ng-content select="chr-menu-overlay" />
